<template>
  <div>
    <div class="bg max-w1200 mrauto mt47 mb51 pd20">
      <div class="pd40 bg-ffffff bor-E6E6E6">
        <!-- 申请城市合伙人 -->
        <div>
          <el-form ref="form2" :model="form2" label-width="11.25rem" label-position="right">
            <el-form-item label="合伙人级别：">
              <el-select v-model="form2.partner_val" placeholder="选择合伙人级别">
                <el-option
                  v-for="(item, index) in partner_val"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系人：">
              <el-input v-model="form2.name" placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="联系电话：">
              <el-input v-model="form2.phone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="所在地区：">
              <el-cascader
                v-model="form2.citytext"
                :options="cityjson"
                :props="{
                  expandTrigger: 'hover',
                  value: 'label',
                }"
                @change="handleChange2"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="上传手持身份证照片：">
              <el-upload
                class="avatar-uploader"
                action="https://pt.baipubang.com/api/other/upload"
                :show-file-list="false"
                :on-success="handleAvatarSuccess4"
                name="media"
                :before-upload="beforeUpload4"
              >
                <img v-if="form2.imgUrl" :src="form2.imgUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="sbmitInfo2()"
                >提交审核</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  partner_apply_view,
  partner_apply_sub,
  edit_partner_view,
  edit_partner_sub,
} from "../../utils/api.js";
import cityjson from "../../assets/js/country-level3-data";
export default {
  data() {
    return {
      form2: {
        partner_val: "",
        name: "",
        phone: "",
        citytext: "", //城市
        province: "", //省
        city: "", //市
        city_area: "", //区
        address: "", //详细地址
        imgUrl: "",
        path: "",
      },
      cityjson: cityjson,
      parent_id: "",
      partner_val: "",
      apply_id: "",
      uid: "",
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        if (this.$route.query.apply_id) {
          this.apply_id = this.$route.query.apply_id;
          this.getInfo();
        }
      },
    },
  },
  mounted() {
    let uid = window.localStorage.getItem("uid");
    if (uid) {
      this.uid = uid;
      this.getpartner();
      if (this.$route.query.apply_id) {
        this.apply_id = this.$route.query.apply_id;
        this.getInfo();
      }
    } else {
      this.$message.error("还未登录，请登录后再使用");
      setTimeout(() => {
        this.$router.push({
          path: "/login",
          query: {navid: 10,},
        });
        // let routeData = this.$router.resolve({
        //   path: "/login",
        //   query: { navid: 10 },
        // });
        // window.open(routeData.href, "_blank");
      }, 1500);
    }
  },
  methods: {
    // 申请合伙人提交审核
    sbmitInfo2() {
      let that = this;
      if (that.apply_id) {
        edit_partner_sub({
          id: that.apply_id,
          province: that.form2.province,
          city: that.form2.city,
          city_area: that.form2.city_area,
          name: that.form2.name,
          phone: that.form2.phone,
          card_img: that.form2.path,
          parent_id: that.parent_id,
          user_type: that.form2.partner_val,
        })
          .then((res) => {
            console.log(res);
            if (res.code != 200) {
              this.$message.error(res.msg);
              return;
            }
            this.$message.success(res.msg);
            setTimeout(() => {
              // this.$router.push({
              //   path: "/indexer",
              //   query: { navid: 1 },
              // });
              let routeData = this.$router.resolve({
                path: "/indexer",
                query: { navid: 1 },
              });
              window.open(routeData.href, "_blank");
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.msg);
          });
      } else {
        partner_apply_sub({
          uid: that.uid,
          province: that.form2.province,
          city: that.form2.city,
          city_area: that.form2.city_area,
          name: that.form2.name,
          phone: that.form2.phone,
          card_img: that.form2.path,
          parent_id: that.parent_id,
          user_type: that.form2.partner_val,
        })
          .then((res) => {
            console.log(res);
            if (res.code != 200) {
              this.$message.error(res.msg);
              return;
            }
            this.$message.success(res.msg);
            setTimeout(() => {
              // this.$router.push({
              //   path: "/indexer",
              //   query: { navid: 1 },
              // });
              let routeData = this.$router.resolve({
                path: "/indexer",
                query: { navid: 1 },
              });
              window.open(routeData.href, "_blank");
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.msg);
          });
      }
    },
    getpartner() {
      let that = this;
      partner_apply_view({
        uid: that.uid,
      })
        .then((res) => {
          console.log(res);
          that.parent_id = res.data.parent_id;
          that.partner_val = res.data.partner_val;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCheckedCitiesChange(value) {
      console.log(value);
      this.form.classtype = value;
      console.log(this.form.classtype);
    },
    // 证件
    beforeUpload4(file) {
      console.log(file);
      const isJPG = file.type === "image/jpeg";
      const isJPG2 = file.type === "image/png";

      if (!isJPG && !isJPG2) {
        this.$message.error("只能上传图片!");
      }
      return isJPG || isJPG2;
    },
    handleAvatarSuccess4(res, file) {
      console.log(res, file);
      this.form2.path = res.data.media;
      this.form2.imgUrl = res.data.path_media;
    },
    // 城市选择
    handleChange2(value) {
      console.log(value);
      this.form2.province = value[0];
      this.form2.city = value[1];
      this.form2.city_area = value[2];
    },
    getInfo() {
      let that = this;
      edit_partner_view({
        apply_id: that.apply_id,
      })
        .then((res) => {
          console.log(res);
          this.form2.partner_val = res.data.val.user_type;
          this.form2.name = res.data.val.name;
          this.form2.phone = res.data.val.phone;
          let add = [];
          add.push(res.data.val.province);
          add.push(res.data.val.city);
          add.push(res.data.val.city_area);
          this.form2.citytext = add;
          this.form2.province = res.data.val.province;
          this.form2.city = res.data.val.city;
          this.form2.city_area = res.data.val.city_area;
          this.form2.imgUrl = res.data.val.card_img;
          this.form2.path = res.data.val.card_path;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
// .bg {
//   background-image: url(../../assets/img/bg.png);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
// }

.el-input.is-disabled .el-input__inner {
  background-color: #ffffff !important;
  border-color: #d2d2d2 !important;
  color: #222222 !important;
  font-size: 0.875rem !important;
  cursor: not-allowed;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 0.375rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 1.75rem;
  color: #8c939d;
  width: 11.125rem;
  height: 11.125rem;
  line-height: 11.125rem;
  text-align: center;
}

.avatar {
  width: 11.125rem;
  height: 11.125rem;
  display: block;
}
</style>
